import React from 'react'
import Alert from './layouts/Alerts'

function Online() {
  return (
    <div>
        <Alert message={"Thanks for submitting the form"} />
    </div>
  )
}

export default Online