import { UserAuth } from "./../context/AuthContext";
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { db } from "../firebase";
import { sendEmailVerification } from "firebase/auth";
import { collection, addDoc, getDocs } from "firebase/firestore";
import RedAlert from "./layouts/RedAlert";
import ReCAPTCHA from "react-google-recaptcha";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [facilityList, setFacilityList] = useState([]);
  const [facility, setFacility] = useState("");
  const [facilityId, setFacilityId] = useState("");
  const [error, setError] = useState("");
  const { createUser, emailVerify } = UserAuth();
  const captchaRef = useRef(null);

  useEffect(() => {
    const fetchFacilityList = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "FacilityList"));
        const list = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Fetched facility list:", list); // Debugging statement
        setFacilityList(list);
      } catch (error) {
        console.error("Error fetching facility list:", error);
      }
    };

    fetchFacilityList();
  }, []);

  useEffect(() => {
    const selectedFacility = facilityList.find(
      (facility) => facility.facilityId === facilityId
    );
    if (selectedFacility) {
      setFacility(selectedFacility.facility);
    } else {
      setFacility("");
    }
  }, [facilityId, facilityList]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    setError("");

    try {
      const userCred = await createUser(email, password);
      const user = userCred.user;
      console.log("User created:", userCred.user.uid);
      await sendEmailVerification(user);

      /*             const userDocRef = await addDoc(collection(db, 'AppUsers'), {
              [user.uid]: { name , facility },
            }); */

      /*             const userDocRef = await addDoc(collection(db, 'AppUsers'), {
              [user.uid]: { name, facility, facilityId },
            }); */

      const userDocRef = await addDoc(collection(db, "AppUsers"), {
        userId: user.uid, // Store user.uid as a field
        name,
        facility,
        facilityId,
        email,
      });

      const faciltyDocRef = await addDoc(collection(db, "Facility"), {
        name,
        facility,
        facilityId,
      });

      // Check if the facility already exists in the Facilities collection
      /*             const facilityRef = doc(db, 'Facility', facilityId);
            const facilityDoc = await getDoc(facilityRef);

            if (facilityDoc.exists()) { */
      // Facility already exists, add the user to the 'users' subcollection
      /*               await setDoc(doc(db, 'Facility', facilityId, 'users', user.uid), {
                name, email
              });
            } else { */
      // Facility doesn't exist, create a new facility with the user
      /*               await addDoc(collection(db, 'Facility'), {
                [facilityId]: {
                  facility,
                  users: {
                    [user.uid]: { name, email },
                  },
                },
              });
            } */

      console.log(
        "User data saved to Firestore:",
        userDocRef.id,
        faciltyDocRef.id
      );
      window.location.href = "/";
      //navigate('/instructions')
      //await emailVerify(email, password)
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src={process.env.PUBLIC_URL + "/logo192.png"}
          alt="FuturFirst"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign up for an account.
        </h2>
      </div>
      {/* <a href="#" className="sm:mx-auto sm:w-full sm:max-w-sm mt-6 max-w-sm rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"> */}
      {/* <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Welcome to HCWM</h2> */}
      {/*     <p className="font-medium text-sm text-justify text-gray-900 dark:text-gray-400">
    Welcome to HCWM – an app powered by assessme – which provides an easy to use tool to give insight into the safe Management of Healthcare Waste.
    </p>
</a>   */}
      {/*     <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-6 max-w-sm"><p className="font-medium text-sm text-justify text-gray-900">We will explore your current practices around Healthcare Waste and the results should lead to an accreditation process.</p>
    <p className="font-medium text-sm text-justify text-gray-900">If you are a new user, please take a moment to register with us and join our community of responsible healthcare providers.</p> 
    <p className="font-medium text-sm text-justify text-gray-900 dark:text-gray-400 mt-6">Thank you for choosing us as your healthcare waste management assessment partner.</p>
</div>  */}
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Name
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  onChange={(e) => setName(e.target.value)}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  type="text"
                  required
                />
              </div>
            </div>
          </div>
          {/*           <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Facility ID
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  onChange={(e) => setFacilityId(e.target.value)}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  type="text"
                  required
                />
              </div>
            </div>
          </div> */}
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Facility ID
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <select
                  value={facilityId} // Ensure value is bound to state
                  onChange={(e) => {
                    setFacilityId(e.target.value); // Update facilityId state
                    console.log("facilityId from state:", e.target.value); // Log facilityId when it's updated
                  }}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  required
                >
                  <option value="">Select Facility ID</option>
                  {facilityList.length > 0 ? (
                    facilityList.map((facility) => (
                      <option key={facility.id} value={facility.facilityId}>
                        {facility.facilityId}
                      </option>
                    ))
                  ) : (
                    <option disabled>Loading facilities...</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Facility
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  value={facility}
                  onChange={(e) => setFacility(e.target.value)}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  type="text"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  type="email"
                  required
                />
              </div>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Password
            </label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  type="password"
                  required
                />
              </div>
            </div>
          </div>
          <ReCAPTCHA
            //sitekey={process.env.REACT_APP_SITE_KEY}
            sitekey="6LcbVWwpAAAAAOGaN9SUVutHUZ9VnOcNkfXfV1IN"
            ref={captchaRef}
          />
          <button className="flex w-full justify-center rounded-md bg-ffmidblue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-900">
            Sign Up
          </button>
        </form>
        {error.code === "auth/email-already-in-use" ? (
          <p className="mt-6">
            <RedAlert
              messageTitle={"Signup failed"}
              messageText="Sorry that email is already in use"
            />
          </p>
        ) : //<p>Sorry that email is already in use</p>
        null}

        <p className="mt-10 text-center text-sm text-gray-500">
          Already have an account?{" "}
          <Link
            to="/"
            className="font-semibold leading-6 text-slate-900 hover:text-slate-800"
          >
            Sign in here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;
