import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

export default function GreenAlert({ message }) {
  return (
    <div className="rounded-md bg-green-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-green-800" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">{message}</p>
        </div>
      </div>
    </div>
  )
}