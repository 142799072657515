import React from 'react'
import Alert from './layouts/Alerts'

function Offline() {
  return (
    <div>Offline
      <Alert message={"Thank you for signing up! Please check your email inbox to verify your email address. It's an important step to complete the registration process."} />
    </div>
  )
}

export default Offline