import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

export const UserDetailsContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged( auth, (user) => {
      if (user) {
        setUser(user);
        setIsLoggedIn(true);
      } else {
        setUser(null);
        setIsLoggedIn(false);
      }
      setIsLoading(false); // Set isLoading to false once authentication state is determined
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserDetailsContext.Provider value={{user, isLoggedIn, isLoading}}>
      {children}
    </UserDetailsContext.Provider>
  );
};

//export { UserDetailsContext, UserProvider };


export const UserAuthDetails = () => {
    return useContext(UserDetailsContext)
}