import React from 'react'

function Button( { message, onClick }) {
return (
<div>
<button
type="button"
className="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
onClick={onClick}
>
{ message }
</button>
</div>
)
}

export default Button